import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { navigate } from 'gatsby'

import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'

import { Decoration } from '../shared/Decoration'

export const MediaSquare = ({ data }) => {
  return (
    <Block
      background={data.backgroundColor ? data.backgroundColor : null}
      onClick={() =>
        data.relatedPage ? navigate(`/fr${data.relatedPage.slug}`) : null
      }
    >
      {data.mainImage ? (
        <Image fluid={data.mainImage.fluid} alt={data.mainImage.title} />
      ) : null}
      {data.title ? (
        <TitleWrapper>
          {data.title.length > 1
            ? data.title.map((word, i) => <span key={i}>{word}</span>)
            : data.title}
        </TitleWrapper>
      ) : null}
      {data.decoration ? (
        <StyledDecoration>
          {data.decoration.map((decoration, i) => (
            <Decoration key={i} data={decoration} />
          ))}
        </StyledDecoration>
      ) : null}
    </Block>
  )
}

const Block = styled.div`
  display: block;
  position: relative;
  background-color: ${(props) =>
    props.background ? `${props.background}` : `${colors.black}`};
  margin-bottom: ${mobileVw(15)};
  width: ${mobileVw(288)};
  height: ${mobileVw(288)};
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: inline-block;
    width: ${desktopVw(225)};
    height: ${desktopVw(225)};
    margin-bottom: ${desktopVw(15)};
  }
`

const TitleWrapper = styled.h2`
  cursor: default;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: ${mobileVw(60)};
  font-family: ${fonts.splandor};
  span {
    display: block;
    color: ${colors.white};

    &:first-of-type {
      color: ${colors.mailleGold};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(60)};
  }
`

const StyledDecoration = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  right: ${mobileVw(20)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(-10)};
    right: ${desktopVw(-20)};
  }
`
