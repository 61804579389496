import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { HeroBlock, Button, SEO } from '../components/shared'
import { InstagramText } from '../components/recipe'
import { MediaSquare } from '../components/community'

import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  fonts,
  colors,
} from '../styles'

const Community = ({
  location,
  data: { contentfulPageCommunity, contentfulGlobalSite },
}) => {
  const {
    heroblock,
    instagramSectionTitle,
    instagramSectionButtonText,
    instagramImages,
    decorations,
    mediaSquares,
    seoDescription,
    seoTitle,
  } = contentfulPageCommunity

  const { instagramLink, instagramIcon, hashtagText } = contentfulGlobalSite

  const titledSquares = []
  const imageSquares = []

  // broski :'( mediaSquares?
  mediaSquares.map((square, i) =>
    square.title ? titledSquares.push(square) : imageSquares.push(square),
  )

  return (
    <StyledCommunitySection>
      <SEO
        description={seoDescription}
        title={seoTitle}
        location={location}
        pageType='Community'
      />
      <HeroWrapper>
        <HeroBlock
          data={{
            ...heroblock,
            subtitle: null,
          }}
          marginTitle={[50]}
        />
      </HeroWrapper>
      <Instagram aria-label='Instagram section'>
        {
          instagramSectionTitle &&
          <div>
            <StyledTitle>
              {instagramSectionTitle.split(' ').map((word, i) => (
                <span key={i}>{word}</span>
              ))}
            </StyledTitle>
            <Wrapper className='desktopOnly'>
              <InstagramText instagramSectionButtonText={instagramSectionButtonText} />
            </Wrapper>
          </div>
        }
        <SquareWrapper>
          {mediaSquares && mediaSquares.map((square, i) => (
            <MediaSquare key={i} data={square} />
          ))}
        </SquareWrapper>
        {
          instagramImages &&
          <>
            <a
              href={instagramLink}
              alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
            >
              <HashtagWrapper>
                <InstagramIcon
                  fluid={instagramIcon.fluid}
                  alt={instagramIcon.title}
                />
                <Hashtag>#{hashtagText}</Hashtag>
              </HashtagWrapper>
            </a>
            <ImageGallery>
              {instagramImages.map((image, i) => (
                <StyledImage key={i} fluid={image.fluid} alt={image.title} />
              ))}
            </ImageGallery>
          </>
        }
        <a
          className='mobileOnly'
          href={instagramLink}
          alt='Ce lien va ouvrir une nouvelle fenêtre: Maille Instagram'
        >
          <Button text={instagramSectionButtonText} />
        </a>
      </Instagram>
      <div>
        <Image
          className='mobileOnly'
          fluid={decorations[0].image.fluid}
          alt={decorations[0].image.title}
        />
        <Image
          className='desktopOnly'
          fluid={decorations[1].image.fluid}
          alt={decorations[1].image.title}
        />
      </div>
    </StyledCommunitySection>
  )
}

const StyledCommunitySection = styled.div`
  overflow: hidden;
`

const Wrapper = styled.div`
  h2 {
    display: none;
  }

  h3 {
    max-width: ${desktopVw(730)};
  }

  button {
    max-width: ${desktopVw(350)};
  }

  padding-bottom: ${desktopVw(65)};
`

const HeroWrapper = styled.div`
  h2 {
    font-size: ${mobileVw(30)};
    line-height: 1;
  }

  @media (min-width: ${desktopBreakpoint}) {
    button {
      display: block;
    }

    h2 {
      font-size: ${desktopVw(60)};
      line-height: 1;
    }

    margin-bottom: ${desktopVw(-65)};
    /* close */
  }
`

const Instagram = styled.section`
  padding: ${mobileVw(40)} ${mobileVw(16)} ${mobileVw(80)};
  background-color: ${colors.black};
  margin-top: ${mobileVw(-50)};
  margin-bottom: ${mobileVw(-45)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
    margin-bottom: ${desktopVw(-155)};
    padding: ${desktopVw(40)} ${desktopVw(0)} ${desktopVw(150)};
  }
`

const SquareWrapper = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 ${desktopVw(300)};
  }
`

const HashtagWrapper = styled.div`
  display: flex;
  margin: ${mobileVw(12)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    align-items: center;
    padding-left: ${desktopVw(150)};
    margin: ${desktopVw(50)} 0 ${desktopVw(50)} 0;
  }
`

const Hashtag = styled.h2`
  color: ${colors.mailleGold};
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(30)};
  padding-left: ${mobileVw(5)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(55)};
  }
`

const InstagramIcon = styled(Image)`
  width: ${mobileVw(30)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(55)};
  }
`

const StyledTitle = styled.h2`
  font-size: ${mobileVw(25)};
  color: ${colors.white};
  font-family: ${fonts.splandor};
  padding-bottom: ${mobileVw(10)};
  span {
    padding-right: ${mobileVw(5)};
    &:first-of-type {
      color: ${colors.mailleGold};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    padding-left: ${desktopVw(128)};
    padding-bottom: 0;
    span {
      padding-right: ${desktopVw(8)};
      &:first-of-type {
        display: inline-block;
      }
    }
  }
`

const StyledImage = styled(Image)`
  width: 44vw;
  margin-bottom: ${mobileVw(6)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(265)};
    margin: ${desktopVw(5)};
  }
`

const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${mobileVw(12)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 0;
    justify-content: center;
  }
`

export const community = graphql`
  query Community($id: String!, $node_locale: String!) {
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      hashtagText
      instagramLink
      instagramIcon {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
    }
    contentfulPageCommunity(id: { eq: $id }) {
      slug
      seoDescription
      seoTitle
      decorations {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
      }
      heroblock {
        title
        breadcrumbsTitle
        background
        subtitle
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      mediaSquares {
        mainImage {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
        title
        relatedPage {
          ...linkTarget
        }
        backgroundColor
      }
      instagramSectionTitle
      instagramSectionButtonText
      instagramImages {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Community
